import { useEffect, useRef, useState } from "react";
import Creatable from "react-select";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { api, recursiveAPI } from "services";
import txnaudio from 'assets/sounds/google_pay-mini.mp3';
import useUniversalStore from "store/universalStore";
import EachRtxn from "./EachRtxn";

function RecursiveTxns(props) {
  const { openKhata, getOpenKhata, allAccounts, khataview } = props;
  
    /*const deleteit = () => {
    switch (deleteObjectType) {
        case "RecursiveTxn":
        api
            .DeleteRecursiveTxn(deleteObject)
            .then(() => {
            updateRefresh("recursive");
            })
            .catch((err) => err);
        break;
    }
    };*/
  const [multiplier, setMultiplier] = useState(1);
  const {updateRefresh} = useUniversalStore()
  const [show, setShow] = useState(false);
  const [txns, setTxns] = useState([]);
  const [options, setOptions] = useState([]);
  const [formattedAmount, setFormattedAmount] = useState("");
  const optionsInt = [
    { value: 1, label: "1 day (Daily)" },
    { value: 7, label: "7 days (Weekly)" },
    { value: 30, label: "30 (Monthly on same date every month)" },
    { value: 32, label: "30 (Monthly on 1st day every month)" },
    { value: 365, label: "365 days (Annually on same date)" },
    { value: null, label: "Custom" },
  ];
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  const tempTriggerDate = new Date();
  tempTriggerDate.setDate(tempTriggerDate.getDate() + 1);
  tempTriggerDate.setHours(0, 0, 0, 0);
  const [values, setValues] = useState({
    amount: "",
    detail: "",
    date: minDate,
    nextTriggerDate: tempTriggerDate,
    account_name: "",
    interval: optionsInt[0],
    errormsg: "",
    successmsg: "",
    loading: false,
  });
  const selectRef = useRef();

  const success = (message) => {
    setValues({
      ...values,
      successmsg: message,
      errormsg: "",
      loading: false,
      date: minDate,
    });

    setTimeout(() => {
      setValues({
        ...values,
        successmsg: "",
      });
    }, 3000);
  };

  const failure = (message) => {
    setValues({
      ...values,
      successmsg: "",
      errormsg: message,
      loading: false,
    });

    setTimeout(() => {
      setValues({
        ...values,
        errormsg: "",
      });
    }, 3000);
  };

  const handleClose = () => {
    setShow(false);
    setValues({
      amount: "",
      detail: "",
      date: "",
      account_name: "",
      interval: optionsInt[0],
    });
  };

  const populateAccountsDropdown = (_accounts) => {
    const _options = [];
    for (var i = 0; i < _accounts.length; i++) {
      _options.push({
        value: _accounts[i].get("name"),
        label: _accounts[i].get("name"),
      });
    }
    if (options.length !== _options.length) {
      setOptions(_options);
    }
  };

  const handleChangeAmount = (e) => {
    e.preventDefault();
    let amountValue = e.target.value.replace(/[₹,]/g, "");
    setValues((prev) => {
      let tempValue = { ...prev };
      if (amountValue === NaN || amountValue === "") {
        tempValue.amount = 0;
        return tempValue;
      } else if (amountValue <= 10000000) {
        tempValue.amount = parseFloat(amountValue.replace(/[^0-9.-]/g, ""));
        return tempValue;
      } else {
        failure("Transaction of more than 1cr is Restricted");
        return tempValue;
      }
    });
  };

  useEffect(() => {
    if (allAccounts) {
      const activeAc = allAccounts.filter((ac) => !ac.get("cancelled"));
      populateAccountsDropdown(activeAc);
    }
  }, [allAccounts]);

  useEffect(() => {
    if (openKhata.id) {
      accountChangeHandler({ value: openKhata.get("name") });
    } else if (window.location.href.includes("khata/")) {
      getOpenKhata();
    } else if (!window.location.href.includes("khata/")) {
      accountChangeHandler({ value: "" });
    }
  }, [openKhata]);

  useEffect(() => {
    formatAmount();
  }, [values.amount]);

  useEffect(() => {
    refreshTxns();
  }, [values.account_name]);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const formatAmount = () => {
    if (values.amount !== null) {
      setFormattedAmount(() => formatter.format(values.amount));
    }
  };

  const refreshTxns = () => {
    if (khataview) {
      // console.log(copiedRcTransactions);
      recursiveAPI.fetchRecursiveTxns(openKhata.id)
        .then(async (result) => {
          // console.log(result);
          setTxns(() => result);
        })
        .catch((error) => console.log(error));
    }
  };

  const successaudio = () => {
      var audio = new Audio(txnaudio)
      audio.play()
  }

  const successpopup = () => {
      const successdialog = document.getElementById('successdialog')
      // Show Success popup
      successdialog.showModal()
      // Hide Success Popup
      setTimeout(() => {
          successdialog.close()
      }, 3000)
  }

  const handleChangeDetails = (e) => {
    let detail = e.target.value;
    setValues((prev) => {
      let tempValue = { ...prev };
      tempValue.detail = detail;
      return tempValue;
    });
  };

  function resetForm() {
    setValues({
      amount: "",
      detail: "",
      date: minDate,
      account_name: "",
      interval: optionsInt[0],
      errormsg: "",
      successmsg: "",
      nextTriggerDate: new Date(),
      loading: false,
    });
  }

  document
    .getElementById("addTxnDrawer")
    ?.addEventListener("hide.bs.offcanvas", function (e) {
      resetForm();
    });

  const handleChangeDate = (e) => {
    setValues((prev) => {
      prev.date = e;
      return prev;
    });
    nextTriggerDateHandler();
  };
  const handleLoading = (state) => {
    setValues({
      ...values,
      successmsg: "",
      errormsg: "",
      loading: state,
    });
  };

  const handleChangeInterval = (e) => {
    setValues((prev) => {
      prev.interval = e;
      return prev;
    });
    nextTriggerDateHandler();
  };


  const accountChangeHandler = (e) => {
    setValues({
      ...values,
      account_name: e ? e.value : "",
    });
  };

  const nextTriggerDateHandler = () => {
    if (values.interval) {
      const tempDate = new Date();
      tempDate.setDate(values.date.getDate());
      tempDate.setMonth(values.date.getMonth());
      tempDate.setFullYear(values.date.getFullYear());
      switch (values.interval.value) {
        case 1:
          tempDate.setDate(tempDate.getDate() + 1);
          break;
        case 7:
          tempDate.setDate(tempDate.getDate() + 7);
          break;
        case 30:
          tempDate.setMonth(tempDate.getMonth() + 1);
          break;
        case 32:
          tempDate.setDate(1);
          tempDate.setMonth(tempDate.getMonth() + 1);
          break;
        case 365:
          tempDate.setFullYear(tempDate.getFullYear() + 1);
          break;

        default:
          tempDate.setDate(tempDate.getDate() + 1);
          break;
      }
      setValues({
        ...values,
        nextTriggerDate: tempDate,
      });
    } else {
      setValues({
        ...values,
        nextTriggerDate: null,
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (values.amount && values.interval && values.date) {
      handleLoading(true);
      values.nextTriggerDate.setHours(0, 0, 0, 0);
      recursiveAPI.addRecursiveTxn(
        openKhata.id,
        values.amount * multiplier,
        values.detail,
        values.date,
        values.interval.value,
        values.nextTriggerDate
      )
        .then((res) => {
          updateRefresh("recursive")
          console.log("Saved");
          handleLoading(false);
          resetForm();
          successaudio();
          successpopup();

          // success("Saved successfully!");
          setTimeout(() => {
            setShow(false);
          }, 2000);
        })
        .catch((error) => {
          failure(error.message);
        });
    } else {
      failure("Fill form!");
    }
  };
  return (
    <>
      <form className="row" onSubmit={handleSave}>
        <div className="py-3 row">
          <div className="col-md-6">
            <label
              className="fw-bolder fs-smaller fonts-for-buttons-label"
              htmlFor="rctxndate"
            >
              Amount
            </label>
          </div>
          <div className="col-md-6">
            <label
              className="fw-bolder fs-smaller fonts-for-buttons-label"
              htmlFor="rctxndate"
            >
              Start Date
            </label>
          </div>
          <div className="col-md-6 h-auto">
            <input
              id="rctxnamount"
              className="form-control"
              placeholder="Amount"
              value={formattedAmount}
              onChange={handleChangeAmount}
              type="text"
              required
            />
          </div>
          <div className="col-md-6 h-auto">
            <DatePicker
              className="form-control w-100 h-auto"
              id="rctxndate"
              value={values.date}
              onChange={handleChangeDate}
              minDate={minDate}
              clearIcon={null}
              format="dd-MM-yy"
            />
          </div>
        </div>
        <div className="py-3">
          <Creatable
            ref={selectRef}
            options={options}
            value={
              values.account_name
                ? { value: values.account_name, label: values.account_name }
                : null
            }
            classNamePrefix="rs"
            isClearable
            placeholder="Select Party Name"
            isDisabled={khataview}
            onChange={accountChangeHandler}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isSelected ? "white" : "black",
                fontSize: 18,
                backgroundColor:
                  state.isFocused || state.isSelected
                    ? state.isFocused
                      ? "#FFCCCB"
                      : "#dc3545"
                    : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "white",
              }),
              clearIndicator: () => ({
                color: "red",
              }),
            }}
          />
        </div>
        <div className="py-3">
          <input className="form-control"
            placeholder="Description"
            value={values.detail}
            onChange={handleChangeDetails}
            type="text" />
        </div>
        <div className="py-3">
          <Select
            options={optionsInt}
            value={values.interval}
            classNamePrefix="rs"
            placeholder="Repeat Schedule"
            onChange={handleChangeInterval}
            required
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isSelected ? "white" : "black",
                fontSize: 14,
                backgroundColor:
                  state.isFocused || state.isSelected
                    ? state.isFocused
                      ? "#FFCCCB"
                      : "#dc3545"
                    : "white",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "white",
              }),
              clearIndicator: () => ({
                color: "red",
              }),
            }}
          />
        </div>
        <div className="py-2">
          {values.nextTriggerDate
            ? "Will start on: " + values.nextTriggerDate.toDateString()
            : console.log(values.date, values.interval)}
        </div>
        <div className="d-flex my-3">
          <div className="col-md-6 d-grid mt-md-0 px-1">
            <button
              type="cancel"
              className="btn btn-success btn-lg"
              data-bs-dismiss="offcanvas"
              onClick={(e) => setMultiplier(1)}
            >
              <b className="fs-5">Cash In</b>
            </button>
          </div>
          <div className="col-md-6 d-grid mt-md-0 px-1">
            <button
              type="submit"
              className="btn btn-danger btn-lg"
              data-bs-dismiss="offcanvas"
              onClick={() => setMultiplier(-1)}
            >
              <b className="fs-5">Cash Out</b>
            </button>
          </div>
        </div>
        {values.loading ? (
          <div className="form-group text-start">
            <div
              className="form-check-label alert alert-warning text-capitalized"
              role="alert"
            >
              <span className="spinner-border spinner-border-sm"></span>{" "}
              Loading...
            </div>
          </div>
        ) : (
          <></>
        )}
        {values.errormsg ? (
          <div className="form-group text-start animate__animated animate__pulse">
            <div
              className="form-check-label alert alert-danger text-capitalized"
              id="editacerror"
              role="alert"
            >
              <i className="bi bi-exclamation-circle-fill"></i>{" "}
              {values.errormsg}
            </div>
          </div>
        ) : (
          <div className="py-2"></div>
        )}
        {values.successmsg ? (
          <div className="form-group text-start">
            <div
              className="form-check-label alert alert-success text-capitalized"
              id="editacsuccess"
              role="alert"
            >
              <i className="bi bi-check-circle-fill"></i> {values.successmsg}
            </div>
          </div>
        ) : (
          <div className="py-2"></div>
        )}
        <br />
        <div className="fs-4 mt-2">Edit Txns</div>
        <div className="fs-smaller mb-2">
          ( Click on transaction to edit it )
        </div>
        <div className="d-flex flex-column">
          {txns.length ? (
            txns.map((txn, index) => (
              <EachRtxn txn={txn} key={txn.id} triggertxns={refreshTxns} />
            ))
          ) : (
            <div className="ms-1">
              {" "}
              - No reccurring transactions for this account currently
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default RecursiveTxns;
