import { useEffect } from "react";



const Dum = ({account, isSelected, onSelect}) => {
    useEffect(() => {
        console.log("mounted");
    }, []);

    return (
        <div className={`py-4 list-group-item ${isSelected ? "bg-danger bg-opacity-25" : ""}`} onClick={onSelect}>
            <div className="">
                {account.name}
            </div>
        </div>
    )
}

export default Dum;