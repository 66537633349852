import MonthView from './MonthView';
import { toggleOffCanvas } from "utils";

const Header = ({ account, handleNewDate, setAnimation }) => (
    <div className="bg-app text-white py-2"
      style={{ display: "flex", justifyContent: "space-between" }}>
      <div role="button"
        onClick={() => toggleOffCanvas('editAccountDrawer')}>
        <h3 className="text-left d-flex">
          {account?.get("photo") ? (
            <div className="profile-img mx-3">
              <img src={account.get("photo")}
                alt="Profile Image"
                className="img-fluid" />
            </div>
          ) : (
            <i className="bi bi-person-circle mx-3"
              style={{ fontSize: "45px", color: "white" }}></i>
          )}

          <div className="d-flex flex-column my-auto me-md-auto">
            {account ? account.get("name").substring(0, 30) : ""}
            <span style={{ fontSize: "14px" }}>
              View Profile
            </span>
          </div>
        </h3>
      </div>
      <MonthView
        setAnimation={setAnimation}
        onDateChange={handleNewDate}
        display={true} />
    </div>
  )

export default Header;