import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountAPI, api, transactionAPI } from "services";
import ModalImage from "react-modal-image";
import { FaFilePdf } from "react-icons/fa";
import { ConfirmationModal } from "components";
import {
  NewTxn, EditTxn, EditRTxn
} from "views/forms";
import useUniversalStore from "store/universalStore";
import { formatMoney } from "utils";

const Txn = ({ data }) => {
  const isPDF = data.get("attachment")?.includes(".pdf");
  const isImage = data.get("attachment")?.match(/\.(jpg|jpeg|png|gif)$/i);
  const { write } = useUniversalStore();
  const transactionId = useUniversalStore((state) => state.transactionId);

  const deletetransaction = async (e) => {
    try{
        await transactionAPI.deleteTransaction(transactionId);
        //Reset the transactionId
        write({ transactionId : null });
      } catch (err) {
        console.log(err);
      }
  }

  const cancelledView = () => {
    return (
      <div className="row align-baseline">
        <div className="col-3" align="right">
          <i className="text-decoration-line-through text-muted">
            {formatMoney(data.get("amount"))}
          </i>
        </div>
        <div className="col-9">
          {data.get("accountId") ? (
            <div className="row">
              <div className="col text-start">
                <a className="text-decoration-line-through text-muted pe-none">
                  {data.get("accountId")?.get("name")}
                </a>
              </div>
            </div>
          ) : null}
          <div className="row text-start">
            <div className="col text-decoration-line-through text-muted">
              {data.get("detail")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const normalView = () => {
    return (
      <div className="row align-baseline">
        <div className="col-3" align="right">
          <i className="text-decoration-none text-color-format">
            {Math.abs(data.get("amount")).toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              style: "currency",
              currency: "INR",
            })}
          </i>
        </div>
        <div className="col-8">
          {data.get("accountId") ? (
            <div className="row">
              <div className="col text-start">
                <a href="#"
                  // Need to open khata view if clicked on account name
                  className="text-decoration-none pe-auto fw-bold fst-italic text-dark">
                  {data.get("accountId")?.get("name")}
                </a>
              </div>
            </div>
          ) : null}
          <div className="row text-start">
            <div className="col">{data.get("detail")}</div>
          </div>
          <div className="row text-start">
            {isPDF && (
              <a
                role="button"
                href={data.get("attachment")}
                target="_blank"
                className="hover:opacity-50"
                style={{ cursor: "pointer" }}
              >
                <FaFilePdf
                  size={200}
                  className="ms-2 text-danger text-decoration-none pe-none "
                  title="Download PDF"
                />
              </a>
            )}

            {isImage && (
              <ModalImage
                small={data.get("attachment")}
                large={data.get("attachment")}
                hideDownload={true}
                hideZoom={false}
                showRotate={true}
                className="col-4 m-2 px-0 img-fluid rounded-2 border border-2 border-danger"
              />
            )}
          </div>
        </div>
        <div className="col-1">
          <a
            className="text-black btn bg-not-transparent-on-hover w-auto"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title="More options"
            align="right"
          >
            <span className="bi bi-three-dots-vertical"></span>
          </a>
          <ul className="dropdown-menu row w-auto">
            <li className="col-12 py-1">
              <button
                className="w-100 btn btn-outline-dark rounded"
                data-bs-toggle="offcanvas"
                data-bs-target="#editTxnDrawer">
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
              <button
                className="w-100 btn btn-danger rounded"
                data-bs-toggle="modal"
                onClick={() => {
                  write({ transactionId : data.id });
                }}
                data-bs-target="#deleteTxnInCashbookModal">
                <span className="bi bi-trash"> Delete</span>
              </button>
            </li>
          </ul>
        </div>
        <NewTxn />
        <EditTxn />
        <EditRTxn />
      </div>
    );
  };

  return (
    <div className="list-group-item bg-transparent">
      {data.get("cancelled") ? cancelledView() : normalView()}
      <ConfirmationModal id="deleteTxnInCashbookModal" onClick={deletetransaction} />
    </div>
  );
}

export default Txn;
