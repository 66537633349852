import { reminderAPI } from "services";


const ReminderView = ({ account }) => {
    const reminderAlert = reminderAPI.getReminderAlert(account.id);
    const nowTime = new Date().getTime();
    const remindBool = account.get("date")
        ? nowTime > account.get("date").getTime() && account.get("enabled")
        : null;
    if(remindBool){
        return (
            <i className="bi bi-patch-exclamation-fill text-danger me-2"
                title="Payment Due!"></i>
        )
    } else {
        return null;
    }
}

export default ReminderView;