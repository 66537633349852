import { useEffect, useState } from "react";
import { clubAPI  } from "services";
import Club from "./Club";

function Index() {
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        fetchClubs();
    }, []);

    const fetchClubs = async () => {
        try {
            const clubs = await clubAPI.getClubs();
            //console.log(clubs[0].get("amount"));
            setClubs(clubs);
        } catch(err) {
            console.log(err.message);
        }
    }

    const joinClub = () => {

    }

    const fetchRounds = (clubId) => {

    }

    const fetchMemberDetail = (userId) => {
        
    }

    //people(members)
    //<Club data={club} />
    return (
        <div align="center">
            <br />
            <h3>Borrow or Invest to fulfill your dreams.</h3><br />
            <ul>
            {clubs.map((club, i) => <Club key={i} club={club} />)}
            </ul>
        </ div>
    )   
}

export default Index;