const Loader = () => {
    return (
        <div class="d-flex justify-content-center" style={{height: "100%"}}>
            <span className="spinner-grow text-danger align-self-center">
                <span class="sr-only">Loading...</span>
            </span>
        </div>
    )
}

export default Loader;