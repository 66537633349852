import { auth, accountAPI } from "services";
import Parse from "./parse.service";
const Reminder = Parse.Object.extend("Reminder");

const user = auth.fetchUser();

export const addReminder = async (id, date, isEnabled) => {
  const reminder = new Reminder();
  const account = await accountAPI.getAccountById(id);
  reminder.set("accountId", account);
  reminder.set("userId", user);
  reminder.set("date", date);
  reminder.set("enabled", isEnabled);
  return await reminder.save();
};

export const toggleReminder = async (id) => {
  const reminder = await getReminderById(id);
  reminder.set("enabled", !reminder.get("enabled"));
  return await reminder.save();
};

export const getReminderAlert = async (id) => {
  const currentdate = new Date().toLocaleString("en-US", {
    hours12: true,
    day: "2-digit",
  });
  //const reminder = await api.getReminderById(id);
  const myAccount = await accountAPI.getAccountById(id);
  const serverDate = myAccount.get("date");
  if (serverDate == currentdate) {
      return { status: "success" };
  } else {
    return { status: "error" };
  }
};