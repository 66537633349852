import { useEffect, useState } from "react";

const Club = ({ club, key }) => {
    return (
        <li key={key}>
            <div className="row">
                <div className="col-2">
                    {Math.abs(club.get("amount")).toLocaleString('en-IN', {
                    maximumFractionDigits: 0,
                    style: 'currency',
                    currency: 'INR'
                    })}
                </div>
                <div className="col-8">
                    <b>{club.get("name")}</b><br />
                    {Math.abs(club.get("amount")/club.get("member")).toLocaleString('en-IN', {
                    maximumFractionDigits: 0,
                    style: 'currency',
                    currency: 'INR'
                    })} monthly contribution
                </div>
                <div className="col-2">
                    <a href= {"/club/" + club.id} className="btn btn-outline-danger ">Select Club</a>
                </div>
            </div>
            <hr />
        </li>
    )
}

export default Club;