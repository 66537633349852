import Reminder from '../forms/Reminder';
import RecursivetxnsView from '../forms/Recursive/RecursivetxnsView';
import ReportsView from './ReportsView';
import useUniversalStore from 'store/universalStore';
import { useEffect, useState } from 'react';
import { profileAPI } from 'services';

const Footer = ({ account }) => {
    const profile = useUniversalStore(state => state.profile);
    const accountId = useUniversalStore((state) => state.accountId);
    
    const sendReminder = () => {
        // alert('reminder');
        const num = account.get("phone");
        const name = profile.get("businessName");
        if (account.get("balance") < 0) {
          const bal = -account.get("balance");
          var line1 = "Payment Reminder : ";
          var line2 = `Balance *₹${bal}* due to *${name}*`;
        } else {
          const bal = account.get("balance");
          var line1 = "Payment details : ";
          var line2 = `Balance *₹${bal}* advance to *${name}*`;
        }
        const link = "Ledger : https://web.bahikhata.org/p/" + accountId;
        const line3 = `Sent via BahiKhata`;
        window.open(
          `https://wa.me/${num}?text=${line1}%0a${line2}%0a${link}%0a%0a${line3}`,
          "_blank"
        );
    };

    if(!account) return null;

    return (
        <div className="col-4 d-flex ms-auto" align="right">
        <div className="col-3 d-flex" align="right">
            <Reminder
                account={account}
                id={account.get("id")}
                currentSetDate={account.get("date")} />
        </div>
        <div className="col-3 d-flex" align="right">
            <RecursivetxnsView account={account} />
        </div>
        <div className="col-3 d-flex" align="right">
            <ReportsView />
        </div>
        <div className="col-3 d-flex" align="right">
            <button className="btn btn-sm bg-app rounded"
                title="Remind" onClick={sendReminder}>
                <i className="bi bi-whatsapp"
                    style={{ fontSize: "2rem", color: "white" }}></i>
            </button>
        </div>
        </div>
    );
}

export default Footer;