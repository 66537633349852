import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";

const MonthView = (props) => {
  const { onDateChange, display, setAnimation } = props;
  const currDate = new Date();
  const [date, setDate] = useState(currDate);
  const [showCalendar, setShowCalendar] = useState(false);
  const [nextMonthAccessible, setNextMonthAccessible] = useState(false);
  const month_name = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const year = date.getFullYear();
    const month = date.getMonth();
    onDateChange(year, month);

    if (month === currDate.getMonth() && year === currDate.getFullYear()) {
      setNextMonthAccessible(false);
    } else {
      setNextMonthAccessible(true);
    }
  }, [date]);

  const handleDateChange = useCallback((newDate) => {
    setDate(newDate);
    setShowCalendar(false);
  }, []);

  const addMonth = useCallback(() => {
    setDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1)
    );
    setAnimation("animate__animated animate__slideInRight fast-animation");
  }, []);

  const subMonth = useCallback(() => {
    setDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1)
    );

    setAnimation("animate__animated animate__slideInLeft fast-animation");
  }, []);

  const isFutureMonth = (month, year) => {
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();
    return year > currYear || (year === currYear && month > currMonth);
  };
  
  return (
    <div className={display ? "khataFilter rounded-3 d-flex"
          : "khataFilter rounded-3 d-none"}>
      <div className="h-100 primary d-flex ms-auto rounded-2">
        <button className="btn btn-sm bg-app" onClick={subMonth}>
          <i className="bi bi-chevron-left"></i>
        </button>
        <label
          className="primary text-white h4 my-auto rounded"
          onClick={() => setShowCalendar((prev) => !prev)}
        >
          {month_name[date.getMonth()] + `'` + date.getFullYear()}
        </label>
        {showCalendar && (
          <div className="calendar-popup">
            <Calendar
              onChange={handleDateChange}
              value={date}
              view="year"
              maxDetail="year"
              showNeighboringMonth={false}
              calendarType="US"
              maxDate={currDate}
              tileClassName={({ date }) => {
                const month = date.getMonth();
                const year = date.getFullYear();
                if (isFutureMonth(month, year)) {
                  return "disabled-month";
                }
                return null;
              }}
            />
          </div>
        )}
        <button
          className="btn btn-sm bg-app"
          onClick={addMonth}
          disabled={!nextMonthAccessible}>
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default MonthView;