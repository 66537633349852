import { useState, useEffect } from "react";
import Txn from "./Txn";
import OpenCloseBalance from "components/OpenCloseBalance";
import { transactionAPI } from "services";
import useUniversalStore from "store/universalStore";

const TxnList = ({ currentDate }) => {
    const txns = useUniversalStore((state) => state.transactions);
    const [openBalance, setOpenBalance] = useState(0);
    const [closeBalance, setCloseBalance] = useState(0);

    useEffect(() => {
        console.log(txns);
    }, [txns]);

    useEffect(() => {
        calculateOpenCloseBalance();
    }, [currentDate, txns]);

    const calculateOpenCloseBalance = () => {
        const openDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),
            currentDate.getDate(),0,0,0,0);
        const closeDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),
            currentDate.getDate(),23,59,59,999);
        
        transactionAPI.sumTillDate(openDate).then((res) => {
            setOpenBalance(res);
        });
        transactionAPI.sumTillDate(closeDate).then((res) => {
            setCloseBalance(res);
        });
    }

    return (
        <div className="row col-md-12">
            <div className="row background overflow-y-scroll cashbook-scroll">
            <div className="col-6 cashin">
                <div className="list-group list-group-flush mb-5">
                <div className="list-group-item header bg-transparent">
                    <div className="row align-baseline header">
                    <div className="col-12 text-success" align="center">
                        <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                        <span className="fs-3 text-uppercase px-3">
                        <b>Cash In</b>
                        </span>
                    </div>
                    </div>
                </div>
                <OpenCloseBalance type="open" sum={openBalance} />
                {txns.filter((txn) => txn.get("amount") > 0)
                    .filter((txn) => !txn.cancelled)
                    .map((txn, i) => <Txn data={txn} key={i} /> )}
                </div>
            </div>
            <div className="col-6 cashout">
                <div className="list-group list-group-flush mb-5">
                <div className="list-group-item bg-transparent">
                    <div className="row align-baseline">
                    <div className="col-12 text-danger" align="center">
                        <i className="bi bi-arrow-up-circle-fill fs-3"></i>
                        <span className="fs-3 text-uppercase px-3">
                        <b>Cash Out</b>
                        </span>
                    </div>
                    </div>
                </div>
                {txns.filter((txn) => txn.get("amount") < 0)
                    .filter((txn) => !txn.cancelled)
                    .map((txn, i) => <Txn data={txn} key={i} />)}
                <OpenCloseBalance type="close" sum={closeBalance} />
                </div>
            </div>
            </div>
        </div>
    );
};

export default TxnList;