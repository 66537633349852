import { useEffect } from "react";
import accounting from "accounting";
import ReminderView from "./ReminderView";
import SubMenu from "./SubMenu";

const Account = ({account, isSelected, onSelect}) => {
    return (
        <li role="button" className={`list-group-item ${isSelected ?
            "bg-danger bg-opacity-25" : "bg-transparent border-none"}`} 
            onClick={onSelect}>
            <div className="row fs-5">
                <div className="col-md-11 py-2 row pe-auto">
                    <div className={`col-md-9 ${account.get("cancelled") ? 
                    " text-muted text-decoration-line-through" 
                    : "fw-bold" }`}>
                        <ReminderView account={account} />
                        {account.get("name").length > 50
                        ? account.get("name").substring(0, 50) + " ..."
                        : account.get("name")}
                    </div>
                    <div className="col-md-3">
                        <span className={`fw-bold ${account.get("balance") < 0
                            ? "text-danger" : "text-success"}
                            ${account.get("cancelled") ? "text-muted text-decoration-line-through" : ""}`}>
                            {accounting.formatMoney(
                                Math.abs(account.get("balance")),
                                "₹",
                                0
                            )}
                        </span>
                    </div>
                </div>
                <div className="col-md-1">
                    <SubMenu account={account} />
                </div>
            </div>
            
        </li>
    )
};

export default Account;