import Parse from "./parse.service";

const Club = Parse.Object.extend("Club");
// Clubs

export const getClubs = async () => {
    try {
      const query = new Parse.Query(Club);
      //query.equalTo("userId", auth.fetchUser());
      const results = await query.find();
      return results;
    } catch(err) {
      return err.message;
    }
  };
  
  export const getClubById = async (id) => {
    try {
        const query = new Parse.Query(Club);
        query.equalTo("objectId", id);
        query.limit(1);
        const result = await query.first();
        return result;
    } catch(err) {
        return err.message;
    }
  };
  