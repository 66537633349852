import Parse from "./parse.service";

const Recurring = Parse.Object.extend("Recurring");
// Recursive Transactions
import { auth, api, accountAPI } from "services";

export const addRecursiveTxn = async (
  accountId,
  amount,
  detail,
  date,
  interval,
  nextTriggerDate
) => {
  const recurring = new Recurring();

  const myAccount = await accountAPI.getAccountById(accountId);
  recurring.set("accountId", myAccount);
  recurring.set("userId", auth.fetchUser());
  recurring.set("amount", parseInt(amount, 10));
  recurring.set("date", date);
  recurring.set("enabled", true);
  recurring.set("detail", detail);
  recurring.set("interval", interval);
  recurring.set("nextTriggerDate", nextTriggerDate);
  return await recurring.save();
};

export const findRecursiveTxn = async (id) => {
  try{
    const query = new Parse.Query(Recurring);
    query.limit(1);
    query.equalTo("objectId", id);
    const results = await query.find();
    return results[0];
  } catch(err) {
    return err.message;
  }
}

export const editRecursiveTxn = async (
  id,
  amount,
  detail,
  date,
  interval,
  nextTriggerDate
) => {
  const txn = await findRecursiveTxn(id);
  txn.set("amount", amount);
  txn.set("detail", detail);
  txn.set("date", date);
  txn.set("interval", interval);
  txn.set("nextTriggerDate", nextTriggerDate);
  return await txn.save();
};

export const deleteRecursiveTxn = async (txn) => {
  // return findRecursiveTxn(id).then(async (txn) => {
  return txn.destroy();
  // }).catch((error)=> {
  //   return error;
  // })
};

export const enableRecursiveTxn = async (id) => {
  try {
    const txn = await findRecursiveTxn(id);
    txn.set("enabled", true);
    return await txn.save();
  } catch (error) {
    return error;
  }
};

export const disableRecursiveTxn = async (id) => {
  try {
    const txn = await findRecursiveTxn(id);
    txn.set("enabled", false);
    return await txn.save();
  } catch (error) {
    return error;
  }
};

export const fetchRecursiveTxns = async (id) => {
  const myAccount = await accountAPI.getAccountById(id);
  const query = new Parse.Query(Recurring);
  query.limit(1000000);
  query.equalTo("accountId", myAccount);
  return await query.find();
};

export const getAllRcTransactions = async () => {
  const query = new Parse.Query(Recurring);
  query.limit(1000000);
  query.ascending("date");
  query.equalTo("userId", auth.fetchUser());
  return await query.find();
};
