import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "services";

function Settings() {
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-1">
          <a role="button" className="btn btn-app" onClick={goBack} title="Back">
            <i className="bi bi-arrow-left"></i>
          </a>
        </div>
        <div className="col-11">
          <h1 className="">Settings</h1>
        </div>
        
        
      </div>
      <hr />
      <p>Coming Soon</p>
    </div>
  )
}

export default Settings;
