import React from "react";
import "./index.css";

const ConfirmPopup = ({ id, onClick }) => {
  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Are you sure ?
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-footer border-0">
            <button type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={onClick}>
              <i className="bi bi-trash-fill"></i> Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPopup;
