import { BsCamera } from "react-icons/bs";

const ProfilePhoto = ({url, loading, onChange}) => {
  return (
    <>
      <img alt="Profile" width="150" height="150"
        src={url ? url : "https://placehold.co/150x150"}
        className="rounded-circle border border-1 border-danger" />
    <label htmlFor="photo">
        <div className="position-relative bg-white rounded-circle" 
        style={{top: "60px", left: "-50px", padding: "5px" }} >
            <BsCamera size={30} role="button"
              className={`rounded-circle border border-1 border-danger text-danger
              ${url ? "opacity-50" : "opacity-100"}`}
              style={{padding: 5}} />
        </div>
    </label>
    <input id="photo" type="file" accept="image/*"
        className="d-none" 
        onChange={onChange}>
    </input>
    {loading ? 
        <div className="progress">
        <div className="progress-bar progress-bar-striped bg-danger" 
            role="progressbar" style={{width: "100%"}} 
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div> : null}
    </>
  );
}

export default ProfilePhoto;