import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowCircleLeft, faArrowLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';
import { api, transactionAPI } from 'services';
import {
  Loader, Status
} from "components";
import {
  toggleOffCanvas
} from "utils";
import useUniversalStore from "store/universalStore";
// import txnaudio from "../images/google_pay-[AudioTrimmer.com].mp3"

const EditTxn = () => {
  const transactionId = useUniversalStore(state => state.transactionId);
  const accountId = useUniversalStore(state => state.accountId);
  const write = useUniversalStore((state) => state.write);

  const initialState = {
    amount: 0,
    accountName: "",
    details: "",
    date: new Date(),
    loading: true,
    status: { type: null, message: "" }
  }
  const [values,setValues] = useState(initialState);
  const MAX_AMOUNT = 9999999;

  useEffect(() => {
    const offCanvas = document.getElementById("editTxnDrawer");
    if(!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
    };

    const handleShown = () => {
      fetchTransactionDetails();
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };

  }, [transactionId]);

  const fetchTransactionDetails = async () => {
    const transaction = await transactionAPI.getTransactionById(transactionId);
    setValues({
      ...values,
      amount: transaction.get("amount"),
      accountName: transaction.get("accountId")?.get("name") || "",
      details: transaction.get("detail") || "",
      date: transaction.get("date"),
      loading: false
    });
  }

  const updateField = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }

  const submitEditTransaction = async (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });
    
    try {
      //Set time along with date to order transactions
      values.date.setHours(values.date.getHours(),
      values.date.getMinutes(), values.date.getSeconds());

      //Update selected account data
      await transactionAPI.updateTransaction(
        transactionId,values.amount,values.details,values.date);
    
      //Update transactions in global store
      write({
        transactions: await transactionAPI.getTransactionsByAccountIdWithMonth(accountId, values.date),
        transactionId: null
      });

      //Reset form and close offcanvas
      setTimeout(() => {
        setValues({
          ...initialState, 
          status: { type: "success", message: "Transaction Updated Successfully!" } 
        });
      }, 100);
    } catch (error) {
      setValues({
        ...values,
        status: { type: "error", message: error.message },
      });
    }
  };

  return (
    <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="editTxnDrawer" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Edit Transaction</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => resetform()}></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid bg-white mb-5 row col-12">
          {values.loading ? <Loader /> :
            <form onSubmit={submitEditTransaction}>
              <div className="row py-3">
                <div className="col-md-6">
                  <input 
                    type="number" className="form-control" 
                    placeholder="Amount"
                    value={values.amount} 
                    onChange={(e) => updateField("amount", Number(e.target.value))}
                    min={-MAX_AMOUNT} max={MAX_AMOUNT}
                    required />
              </div>
              <div className="col-md-6 border-present mt-4 mt-md-0">
                <DatePicker 
                  className="w-100"
                  format="dd-MMM-yyyy"
                  clearIcon={null}
                  calendarIcon={null}
                  value={values.date} 
                  onChange={(dateValue) => updateField("date", dateValue)}
                  maxDate={new Date()} />
              </div>
            </div>
            <div className="py-3">
              <input type="text" 
                className="form-control"
                placeholder="Account Name"
                disabled value={values.accountName} />
            </div>
            <div className="py-3">
              <input type="text" 
                className="form-control"
                placeholder="Details" maxLength="50" 
                value={values.details}
                onChange={(e) => updateField("details", e.target.value)} />
            </div>
            <Status type={values.status.type} message={values.status.message} />
            <div className="row my-3">
              <div className="col-md-12 d-grid">
                <button type="submit" 
                  className="btn btn-danger block py-3" data-bs-dismiss="offcanvas">
                  <b className="fs-5">Update</b>
                </button>
              </div>
            </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}
export default EditTxn;
