import React, { useState, useContext } from 'react';
import ModalImage from "react-modal-image";
import Parse from '../../services/parse.service';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import Dum from './Dum';

const Dummy = () =>  {
    const accounts = [
      {id: 1, name: "Abhijeet", balance: 200},
      {id: 2, name: "Goel", balance: 500},
      {id: 3, name: "Chintu", balance: 1000},
      {id: 4, name: "Sansrati", balance: 200},
      {id: 5, name: "Jain", balance: 500},
      {id: 6, name: "Bittu", balance: 1000},
      {id: 7, name: "Abhijeet", balance: 200},
      {id: 8, name: "Goel", balance: 500},
      {id: 9, name: "Chintu", balance: 1000},
      {id: 10, name: "Sansrati", balance: 200},
      {id: 11, name: "Jain", balance: 500},
      {id: 12, name: "Bittu", balance: 1000}
    ];

    const [selectedId, setSelectedId] = useState(null);
    /*
    const steps = [
        {
            id: 'intro1',
            attachTo: { element: ".nav-link[href='/']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Exit',
                  type: 'cancel'
                },
                {
                  classes: 'btn btn-danger mx-3 my-3',
                  text: 'Next',
                  type: 'next'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'highlight',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Welcome to Bahi Khata',
              text: ['It is a simple app to manage your payabale/receivables and cashflows.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        },
        {
            id: 'intro2',
            attachTo: { element: ".nav-link[href='/cashbook']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Back',
                  type: 'back'
                },
                {
                  classes: 'btn btn-danger',
                  text: 'Next',
                  type: 'next'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'high',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Khatabook',
              text: ['It helps to manage payabale/receivables.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        },
        {
            id: 'intro3',
            attachTo: { element: ".nav-link[href='/passbook']", on: 'right'},
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                  setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                  }, 500);
                });
              },
              buttons: [
                {
                  classes: 'btn btn-light',
                  text: 'Back',
                  type: 'back'
                },
                {
                  classes: 'btn btn-danger',
                  text: 'Exit',
                  type: 'cancel'
                }
              ],
              classes: 'text-light bg-danger p-3',
              highlightClass: 'high',
              scrollTo: false,
              cancelIcon: {
                enabled: true,
              },
              title: 'Passbook',
              text: ['It helps to manage payabale/receivables.'],
              when: {
                show: () => {
                  console.log('show step');
                },
                hide: () => {
                  console.log('hide step');
                }
              }
        }
    ]

    const tourOptions = {
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        }
      },
      useModalOverlay: false
    };
  
    function MyButton() {
      const tour = useContext(ShepherdTourContext);
    
      return (
        <button className="button dark" onClick={tour.start}>
          Start Tour
        </button>
      );
    }
    */

    const onSelect = (val) => {
      setSelectedId(val);
      console.log(selectedId);
    }

    return (
        <div className="d-grid vh-100">
            {/*<ShepherdTour steps={steps} tourOptions={tourOptions} >

                <MyButton />
            </ShepherdTour>*/}
            <div className="list-group row col-2 overflow-scroll">
              { accounts.map((item) => {
                return (
                  <Dum 
                    key={item.id} 
                    account={item} 
                    isSelected={item.id == selectedId} 
                    onSelect={() => onSelect(item.id)} />
                )
              })
            }
            </div>
        </div>
    )
}

export default Dummy;