  import Parse from "./parse.service";

  export const sendOTP = async (mobile) => {
    const params = { mobile: mobile };
    try {
        const result = await Parse.Cloud.run("sendOTP", params)
        return result;
    } catch (err) {
        return err;
    }
  };

  export const resendOTP = async (mobile, type) => {
    const params = { mobile: mobile, OTPtype: type };
    try {
        const result = await Parse.Cloud.run("resendOTP", params)
        return result;
    } catch (err) {
        return err;
    }
  };

  export const verifyOTP = async (mobile, otp) => {
    const params = { mobile: mobile, otp: otp };
    try {
        const result = await Parse.Cloud.run("verifyUser", params)
        return result;
    } catch (err) {
        return err;
    }
  };
  
  export const sendPaidSMS = async (amount, party, url, mobile) => {
    const params = { amount: amount, party: party, url: url, mobile: mobile };
    try {
        const result = await Parse.Cloud.run("PaidSMS", params)
        return result;
    } catch (err) {
        return err;
    }
  };
  
  export const sendReceiveSMS = async (amount, party, url, mobile) => {
    const params = { amount: amount, party: party, url: url, mobile: mobile };
    try {
        const result = await Parse.Cloud.run("ReceiveSMS", params)
        return result;
    } catch (err) {
        return err;
    }
  };
  