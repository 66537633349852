import React from "react";
import {  BrowserRouter as Router, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function NoMatch(){
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <div className="mb-4 lead">
          <div className="animate-bounce">🤔</div>
          Oops! Page not found
        </div>
        <p className="mb-4">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link 
          to="/"
          className="btn btn-danger rounded-pill px-4 py-2"
        >
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Back to Home
        </Link>
      </div>

      <style>{`
        .animate-bounce {
          animation: bounce 1s infinite;
          font-size: 2rem;
          margin: 1rem 0;
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }
      `}</style>
    </div>
  )
}

export default NoMatch;
