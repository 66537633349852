import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Navigate } from 'react-router-dom';
import { auth, sms } from 'services';
import useUniversalStore from 'store/universalStore';
import { images } from 'assets/themes';
import "./Login.css";
import { Status } from 'components';

const Login = () => {
    const { t } = useTranslation();
    const write = useUniversalStore((state) => state.write);
    const user = useUniversalStore((state) => state.user);
    
    const [selectedCountry, setSelectedCountry] = useState(0)
    const [otpSent, setOtpSent] = useState(false)
    const [invalid, setInValid] = useState(true)
    const [otpVerified, setOtpVerified] = useState(false)
    const [resendWaitTime, setResendWaitTime] = useState(30)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        phone: '',
        otp: '',
        status: {type: '', message: ''}
    })

    const countries = [
        {
            name: 'india',
            country_code: 'IN',
            dialing_code: '91',
            flag_url: images.India
        },
        {
            name: 'nepal',
            country_code: 'NP',
            dialing_code: '977',
            flag_url: images.Nepal
        },
        {
            name: 'bangladesh',
            country_code: 'BD',
            dialing_code: '880',
            flag_url: images.Bangladesh
        },
        {
            name: 'pakistan',
            country_code: 'PK',
            dialing_code: '92',
            flag_url: images.Pakistan
        }
    ]

    const failure = (message) => {
        setValues({
            ...values,
            status: {type: 'error', message: message}
        })

        setTimeout(() => {
            setValues({
                ...values,
                status: {type: '', message: ''}
            })
        }, 5000)
    }

    const success = (message) => {
        setValues({
            ...values,
            status: {type: 'success', message: message}
        })

        setTimeout(() => {
            setValues({
                ...values,
                status: {type: '', message: ''}
            })
        }, 5000)
    }

    useEffect(() => {
        if (resendWaitTime && otpSent && !otpVerified)
            setTimeout(() => {
                setResendWaitTime(() => resendWaitTime - 1)
                // console.log(resendWaitTime);
            }, 1000)
    }, [resendWaitTime])

    useEffect(() => {
        //Check whether phone number is valid or not
        setInValid(!validatePhone());
    }, [values.phone])

    const validatePhone = () => {
        const temp1 = isValidPhoneNumber(
            '+' + countries[selectedCountry].dialing_code + values.phone
        ) || values.phone == ''
        let temp2;
        switch (selectedCountry) {
            case 0:
                // India
                temp2 = values.phone.length == 10
                break
            case 1:
                // Nepal
                temp2 = values.phone.length == 8
                break
            case 2:
                // Bangladesh
                temp2 = values.phone.length == 10 || values.phone.length == 9
                break
            case 3:
                // Pakistan
                temp2 = values.phone.length == 10
                break

            default:
                temp2 = false
                break
        }
        
        return temp1 && temp2
    }

    const handlePhoneChange = (e) => {
        setValues((values) => ({
            ...values,
            phone: e.target.value
        }))
    }

    const handleOtpChange = (e) => {
        setValues((values) => ({
            ...values,
            otp: e.target.value
        }))
    }

    const sendOTP = async (e) => {
        e.preventDefault();        
        setLoading(true);
        setOtpVerified(false)
        const result = await sms.sendOTP(
            countries[selectedCountry].dialing_code + values.phone);
        if (result?.type == 'success') {
            // otp sent
            setResendWaitTime(29)
            setOtpSent(true);
            setLoading(false);
            success('OTP sent to ' + values.phone + ' successfully !');
        } else {
            // error
            failure(result.message)
        }
    }

    const resendOTP = async (e, resendType) => {
        e.preventDefault();
        // alert("resend")
        const result = await sms.resendOTP(
            countries[selectedCountry].dialing_code + values.phone, resendType);
        if (result.type == 'success') {
            success('OTP resent successfully!')
            setResendWaitTime(29)
        } else {
            failure(result.message)
        }
    }

    const verifyFunction = async (e) => {
        e.preventDefault();
        
        try {
            const result = await sms.verifyOTP(
              countries[selectedCountry].dialing_code + values.phone,
              values.otp
            );

            if (result.type != "error") {
                setLoading(true);
                success("OTP verified successfully!");

                const loginResult = await auth.loginUsingSession(result.sessionToken);
                if (loginResult.status === "success") {
                    // update user in global store
                    write({user: loginResult.response});

                    window.location = "/";
                } else {
                    failure("Login failed. Please try again.");
                }
            } else if (result.message === "OTP not match") {
                failure("OTP is incorrect! Please enter correct otp.");
            } else {
                failure("Something went wrong!");
            }
        } catch (error) {
            failure(error.message);
        }
    }
    
    if (user) return <Navigate to="/" />

    return (
        <div className="auth-container">
            <div className="auth-wrapper">
                <div className="auth-card">
                    <div className="auth-header">
                        <img src={images.logo} alt="Logo" className="auth-logo" />
                        <div className="auth-title">
                            <h1>Welcome to</h1>
                            <h1 className="text-danger">{t('Bahi Khata')}</h1>
                        </div>
                    </div>

                    <form onSubmit={otpSent ? verifyFunction : sendOTP} className="auth-form">
                        <div className="phone-input-group">
                            <div className="country-dropdown">
                                <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    title={countries[selectedCountry].name.toUpperCase()}
                                >
                                    <img
                                        src={countries[selectedCountry].flag_url}
                                        alt={countries[selectedCountry].name}
                                        className="country-flag"
                                    />
                                    <span className="country-code">
                                        +{countries[selectedCountry].dialing_code}
                                    </span>
                                </button>
                                <ul className="dropdown-menu">
                                    {countries.map((country, index) => (
                                        <li key={country.country_code}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => setSelectedCountry(index)}
                                                type="button"
                                            >
                                                <img src={country.flag_url} alt={country.name} className="country-flag" />
                                                <span className='mx-2'>+{country.dialing_code}</span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <input
                                type="number"
                                className={`w-100 form-control-borderless ${invalid ? 'is-invalid' : values.phone ? 'is-valid' : ''}`}
                                placeholder="Phone number"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                    setInValid(true);
                                }}
                                value={values.phone}
                                onChange={handlePhoneChange}
                                required
                            />
                        </div>

                        {otpSent && (
                            <div className="otp-section">
                                <input
                                    type="number"
                                    className="form-control border"
                                    placeholder="Enter OTP"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                    }}
                                    value={values.otp}
                                    onChange={handleOtpChange}
                                    required
                                />
                                
                                <div className="resend-section">
                                    {resendWaitTime > 0 ? (
                                        <span className="timer">Resend OTP in {resendWaitTime}s</span>
                                    ) : (
                                        <div className="resend-buttons">
                                            <button onClick={(e) => resendOTP(e,"text")} className="btn btn-outline-danger">
                                                Resend via SMS
                                            </button>
                                            <button onClick={(e) => resendOTP(e,"call")} className="btn btn-outline-danger">
                                                Get OTP on Call
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {values.status.type && (
                            <Status 
                                type={values.status.type}
                                message={values.status.message}
                            />
                        )}

                        <button
                            type="submit"
                            disabled={invalid || loading}
                            className="btn btn-danger py-3"
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            ) : null}
                            {loading ? 'Please wait...' : otpSent ? 'Verify OTP' : 'Send OTP'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
