import { recursiveAPI } from "services";

import useUniversalStore from "store/universalStore";

function EachRtxn(props) {
  const { txn, triggertxns } = props;
  const { write } = useUniversalStore();

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const handleEdit = (e) => {
    e.preventDefault();
    const data = txn;
    const updateData = { editRcTxn: data };
  };

  const handleDelete = () => {
    write((prev)=>{
      let tempVar = {...prev.deleteTransactions}
      tempVar.type="RecursiveTxn"
      tempVar.object=txn
      return {...prev, deleteTransactions: tempVar}
    })
    const toDel = { delRcTxn: txn };
    // const confirmation = confirm("Confirm Deletion ?");
    // if (confirmation) {
    //   DeleteRecursiveTxn(data.id);
    //   triggertxns();
    //   console.log("hello");
    // }
    // alert("Feature not ready");
  };

  const handleEnable = () => {
    recursiveAPI.enableRecursiveTxn(txn.id).then((res) => {
      // trigger update
      triggertxns();
    }).catch((error) => console.log(error));
  };

  const handleDisable = () => {
    recursiveAPI.disableRecursiveTxn(txn.id).then((res) => {
      // trigger update
      triggertxns();
    }).catch((error) => console.log(error));
  };


  return (
    <div className="col-12 row mx-auto">
      <div className={txn.get("enabled") ? "btn btn-outline-danger rounded-top mt-1 w-100 pt-2" : "btn btn-outline-secondary rounded-top mt-1 w-100 pt-2"} onClick={handleEdit} title="Click to Edit" data-bs-toggle="offcanvas" data-bs-target="#editRcTxnDrawer" aria-controls="editRcTxnDrawer">
        <div className="col-6" align="left"><strong>Amount:</strong> {formatter.format(txn.get("amount"))}</div>
        <div className="col-6" align="left"><strong>Start Date:</strong> {txn.get("date").toLocaleDateString()}</div>
        <div className="col-6" align="left"><strong>Next Txn Date:</strong> {txn.get("nextTriggerDate")?.toLocaleDateString()}</div>
        <div className="col-6" align="left"><strong>Interval:</strong> {txn.get("interval")}</div>
        {
          txn.get("detail") ?
            <div className="col-12" align="left">Description: {txn.get("detail")}</div>
            : null
        }
      </div>
      <a className="rounded-bottom border-top-0 mb-1 w-100 pb-2 row ms-0 px-0">
        <i className={txn.get("enabled") ? "btn btn-outline-danger col-6 bi bi-trash" : "btn btn-outline-secondary col-6 bi bi-trash"} onClick={handleDelete} data-bs-toggle="modal" data-bs-target="#delModal"></i>
        <i className={txn.get("enabled") ? "btn btn-outline-danger col-6 bi bi-toggle2-on" : "btn btn-outline-secondary col-6 bi bi-toggle2-off"} onClick={txn.get("enabled") ? handleDisable : handleEnable} title={txn.get("enabled") ? "Disable" : "Enable"}></i>
      </a>
    </div>
  );
}

export default EachRtxn;
