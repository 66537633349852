import { useState, useEffect } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { accountAPI, transactionAPI } from "services";
import {
  ExpandableButton, Loader, ReminderAlert
} from "components";
//import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";
const accounting = require("accounting");
import {
  NewTxn, EditTxn, EditRTxn
} from "views/forms";

import "animate.css/animate.min.css";
import Header from "./Header";
import Footer from "./Footer";
import TxnsListView from "./TxnsListView";

const Khata = () => {
  const accountId = useUniversalStore((state) => state.accountId);
  
  const [account, setAccount] = useState(null);
  const [month, setMonth] = useState(new Date());
  
  //TODO : needs to be moved to relevant component
  const [animation, setAnimation] = useState("");
  const write = useUniversalStore((state) => state.write);
  //const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  
  useEffect(() => {
    fetchAccount();
    fetchTransactions();
    
  }, [accountId, month]);

  const fetchAccount = async () => {
    try {
      const result = await accountAPI.getAccountById(accountId);
      setAccount(result);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchTransactions = async () => {
    try {
      const results = await transactionAPI.getTransactionsByAccountIdWithMonth(
        accountId, month);
      
      write({transactions: results});
    } catch (error) {
      console.error(error);
    }
  }

  const editTxn = (data) => {
    write({transactionId: data.id});
  };

/*  const confirmit = (txn) => {
    write((prev) => {
      let tempVar = { ...prev.deleteTransactions };
      tempVar.type = "Transaction";
      tempVar.object = txn;
      return { ...prev, deleteTransactions: tempVar };
    });
    const toDel = { delTxn: txn };
    
    // setDeletionOf(txn);
  };*/

  const changeMonth = (year, month) => {
    let newMonth = new Date(year, month);
    setMonth(newMonth);
  };

  if(!account) return <Loader />;

  return (
    <>
      <div className="container p-0">
        <Header account={account} handleNewDate={changeMonth} 
          setAnimation={setAnimation} />

        <TxnsListView monthlyDate={month} editTxn={editTxn} 
          animation={animation} setAnimation={setAnimation} />

        {/* Only shows button when a valid user exists */}
        <div style={{ paddingBottom: 125, paddingRight: 50 }} 
          className="position-fixed bottom-0 end-0">
          <ExpandableButton
            title="New Entry"
            clickForm="addTxnDrawer"
            // onClick={(e) => goToNextStep()}
            icon={faPencil} />
        </div>
        
        <div className="sticky-bottom bg-app py-3 px-3 text-white d-flex">
          <h3 className="my-auto text-left text-md-left"
            title="Account Balance">
            Balance :{" "}
            {accounting.formatMoney(Math.abs(account.get("balance")), "₹", 0)}
          </h3>
          <Footer account={account} />
        </div>
        <NewTxn />
        <EditTxn />
        <EditRTxn />
      </div>
    </>
  );
};

export default Khata;
