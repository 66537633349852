import React, { useEffect, useState } from 'react'
import { Routes, Route, Link, Navigate, useLocation } from 'react-router-dom'
import { 
    Header, Menu, Footer 
} from 'components';

import { Login, Profile } from 'views/Auth';
import Cashbook from 'views/Home/Cashbook'
import Passbook from 'views/Home/Passbook'
import Khatabook from 'views/Home/Khatabook'
import Settings from 'views/Settings'
import NoMatch from 'views/NoMatch.js'
import Public from 'views/Public'
import Dummy from 'views/Dummy'

import Committee from 'views/Committee'
import ClubDetail from 'views/Committee/ClubDetail'

import Help from 'views/Help';

import OnboardTour from 'views/OnboardTour'
import Onboarding from 'views/Onboarding'
import OnboardingTasksModal from 'components/onboarding-tasks'
import { Toaster } from 'sonner'
import useUniversalStore from 'store/universalStore';
import { auth } from 'services';

function App() {
    const location = useLocation();
    const fetchInitialData = useUniversalStore((state) => state.fetchInitialData);
    const showFooterPaths = ['/settings', '/profile', '/help'];
    const shouldShowFooter = showFooterPaths.includes(location.pathname);
    
    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    return (
        <div className="vh-100 vw-100 overflow-scroll">
            <Toaster />
            {/* <OnboardTour /> */}
            {/* <OnboardingTasksModal /> */}
            {/* <Onboarding /> */}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={ <PrivateRoute><Khatabook /></PrivateRoute>} />
                <Route path="/cashbook" element={<PrivateRoute><Cashbook /></PrivateRoute>} />
                <Route path="/passbook" element={<PrivateRoute><Passbook /></PrivateRoute>} />
                <Route path="/p">
                    <Route path=":id" element={<Public />} />
                </Route>
                <Route path="/dummy" element={<PrivateRoute><Dummy/></PrivateRoute>} />
                <Route path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
                <Route path="/funding" element={<PrivateRoute><Committee/></PrivateRoute>} />
                <Route path="/club">
                    <Route path=":id" element={<PrivateRoute><ClubDetail /></PrivateRoute> } />
                </Route>
                <Route path="/profile" element={<PrivateRoute><Profile />{' '}</PrivateRoute> } />
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
            {/* {shouldShowFooter && <Footer />} */}
        </div>
    )
}

const PrivateRoute = ({ children }) => {
    const user = auth.fetchUser();
    if(!user){
        return <Navigate to="/login" />;
    }
    return <><Header />{children}</>;
}

const LiteView = ({ children }) => {
    const user = auth?.fetchUser();
    //console.log(user);
    //console.log(window.location.href);
    console.log(children);
    if (window.location.href.includes('/p/')) {
        return (
            <>
                {user ? <Header /> : null}
                {children}
            </>
        )
    } else {
        console.log("returning null");
        return (
            <>
            {children}
            </>
        );
    }
}

export default App
