import React, { useEffect, useState } from "react";
const accounting = require('accounting');
import moment from 'moment';
import ModalImage from "react-modal-image";

function Txn(props) {
  const { data } = props;

  const cancelledView = () => {
    return (
      <div className="d-flex mx-0 p-0">
        <div className="col-3 w-25 px-0 text-end pe-1">
          <i className="text-decoration-line-through text-muted fs-smaller">
            {accounting.formatMoney(Math.abs(data.get("amount")), '₹', 0)}
          </i>
        </div>
        <div className="col-9 w-75 d-flex flex-column p-0 ps-1">
            <div className="text-decoration-line-through text-muted col-12 w-100" align="left">
            <small>{data.get("detail")}</small>
            </div>
            <div className="text-decoration-line-through text-muted fst-italic col-12 w-100" align="right">
              <small><small><i>{moment(data.get("date")).format('DD MMM')}</i></small></small>
          </div>
        </div>
      </div>
    )
  }

  const normalView = () => {
    return (
      <div className="d-flex mx-0 p-0">
        <div className="col-3 w-25 px-0 text-end pe-1">
          <i className="fs-smaller fw-bold">
            {accounting.formatMoney(Math.abs(data.get("amount")), '₹', 0)}
          </i>
        </div>
        <div className="col-9 w-75 d-flex flex-column p-0 ps-1">
            <div className="col-12 text-start">
            <small>{data.get("detail")}</small>
            </div>
            <div className="col-12" align="right">
            <small><small><i>{moment(data.get("date")).format('DD MMM')}</i></small></small>
          </div>
          <div className="d-flex">
            <div className="col-12 col-md-6" align="right">
              {data.get("attachment") ?
                <ModalImage
                  small={data.get("attachment")}
                  large={data.get("attachment")}
                  className="my-2 img-fluid rounded-2 border-app"
                  align="right"
                />
                : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <li className="list-group-item bg-transparent pe-0">
      <div className="row align-items-baseline">
        {data.get("cancelled") ? cancelledView() : normalView()}
      </div>
    </li>
  )
}

export default Txn;
