import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { images } from 'assets/themes';
import { auth } from 'services';
import useUniversalStore from 'store/universalStore';
import { Menu } from 'components';

function Header() {
    const user = useUniversalStore((state) => state.user);

    return (
        <>
            <nav className="navbar navbar-expand bg-app">
                <div className="container-fluid">
                    <span className="me-auto">
                        <Link className="navbar-brand col-9" to="/" title="Home">
                            <img src={images.logo}
                                height="40"
                                className="ff-serif" />
                            <span className="text-light mx-2"></span>
                        </Link>
                    </span>
                    <div className="collapse navbar-collapse" id="myNav">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item mx-2">
                                <NavLink className="nav-link fs-6 text-light text-uppercase" 
                                    to="/">
                                    Khata
                                </NavLink>
                            </li>
                            <li className="nav-item mx-2">
                                <NavLink className="nav-link fs-6 text-light text-uppercase" 
                                    to="/cashbook">
                                    Cashbook
                                </NavLink>
                            </li>
                            <li className="nav-item mx-2">
                                <NavLink className="nav-link fs-6 text-light disabled text-uppercase"
                                    to="/passbook">
                                    Passbook{' '}
                                    <span className="bg-white text-dark badge">
                                        Coming Soon
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-item mx-2">
                                <NavLink className="nav-link fs-6 text-light text-uppercase"
                                    to="/funding">
                                    Funding{' '}
                                </NavLink>
                            </li>
                        </ul>

                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link text-light fs-2 py-0 me-2"
                                    data-bs-toggle="offcanvas"
                                    href="#menuDrawer"
                                    role="button">
                                    <i className="bi bi-person-circle"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Menu />
        </>
    )
}
export default Header
